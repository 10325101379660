import { template as template_96ed64f404a442828e0e3a89979c516d } from "@ember/template-compiler";
const SidebarSectionMessage = template_96ed64f404a442828e0e3a89979c516d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
