import { template as template_d61b95889c484affb6a493b8b9f7dd4a } from "@ember/template-compiler";
const FKControlMenuContainer = template_d61b95889c484affb6a493b8b9f7dd4a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
