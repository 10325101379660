import { template as template_ca98c4485a4347e7b70b4481463c7b15 } from "@ember/template-compiler";
const FKLabel = template_ca98c4485a4347e7b70b4481463c7b15(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
